import moment from "moment";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

function TransactionExportPdf({pdfData}){
    const conponentPDF = useRef();


    // console.log(pdfData)

// useEffect(()=>{

// },[pdfData]);

        const generatePDF= useReactToPrint({ 

            content: ()=>conponentPDF.current,
            documentTitle:"Transactions pdf",
            onAfterPrint:()=>alert("Data saved in PDF")
        });


        
    

    return (
        <>
         <div style={{ position: 'absolute', left: '-9999px', top: '0' }}>
            <div ref={conponentPDF} style={{width:'80%'}}>
                <h2 class="text-center border-bottom"><u>Liste des paiements collectés</u></h2>
                <table class="table table-striped"  style={{"text-align": "center", height:"20px", paddingTop: 0, paddingBottom: "-5px"}}>
                    <thead className="bg-light">
                    <tr>
                        <th style={{ border: '2px solid black' }}>
                        Date
                        </th>
                        <th style={{ border: '2px solid black' }}>ID-TRANSACTION</th>
                        <th style={{ border: '2px solid black' }}>SITE ID</th>
                        <th style={{ border: '2px solid black' }}>COMMERCIAL</th>
                        <th style={{ border: '2px solid black' }}>NUMERO</th>
                        <th style={{ border: '2px solid black' }}>OPERATEUR</th>
                        <th  style={{ border: '2px solid black' }}>MONTANT(XOF)</th>
                    </tr>
                    </thead>
                    <tbody>
                
                        {
                            pdfData?.map((trans,index)=>
                            <tr>
                                <td style={{ border: '2px solid black' }}>{moment(trans.cpm_trans_date).format("Do MMMM YYYY H:mm:ss")}</td>
                                <td style={{ border: '2px solid black' }}>{trans.transaction_id}</td>
                                <td style={{ border: '2px solid black' }}>{trans.siteID_distripay}</td>
                                <td style={{ border: '2px solid black' }}>{trans.distri_seller_name}</td>
                                <td style={{ border: '2px solid black' }}>{trans.tel_payment}</td>
                                <td style={{ border: '2px solid black' }}>{trans.operateur}</td>
                                <td style={{ border: '2px solid black' }} className="text-center">{trans.montant_initial} </td>
                               
                            
                            </tr>
                            )
                        }
                    
                    </tbody>
                </table>
            </div>
        </div>

        <button type="button" class="btn btn-danger float-end mx-1" onClick={generatePDF}>
        <i class="bi bi-star me-1"></i> Export PDF
    </button>
        </>

    )
}

export default TransactionExportPdf;