import {Routes as Switch, Route} from 'react-router-dom';
import Login from './auth/Login';
import Home from './modules/Home';
import PaiementRecolte from './modules/PaiementRecolte';
import Utilisateur from './modules/Utilisateur';
import Profil from './modules/Profil';
import Reversement from './modules/Reversement';
import MarchandsPage from './modules/Marchands';
import PermissionPage from './modules/Permission';
import ControledaccesPage from './modules/ControleAcces';
import PaiementRecolteAll from './modules/PaiementRecolteAll';
import AgentPage from './modules/agent_page';

function Main(){
    return (
        <div>
            <Switch>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Home />} />
                <Route path="/paiements-recoltes" element={<PaiementRecolte />} />
                <Route path="/paiements-recoltes-all" element={<PaiementRecolteAll />} />
                <Route path="/utilisateur-marchands" element={<MarchandsPage />} />
                <Route path="/utilisateurs" element={<Utilisateur />} />
                <Route path="/people-users" element={<AgentPage />} />
                <Route path="/profil-marchand" element={<Profil />} />
                <Route path="/reversement-marchand" element={<Reversement />} />
                <Route path="/users-permissions" element={<PermissionPage />} />
                <Route path="/privileges-acces" element={<ControledaccesPage />} />
            </Switch>
        </div>
    )
}

export default Main;