import { useContext, useEffect, useState } from "react";
import BaseUrl from "../BaseUrl";
import ContentPrincipal from "../Content";
import UserAuth, { UserContext } from "../auth/Usercheck";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import can from "../auth/CanUser";

const url = BaseUrl();
function Profil()
{
    const {user, setUsers} = useContext(UserContext);
    const navigate = useNavigate();
    const [loadingform,setLoadingform] = useState(false);
    const [errors,setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [ribData,setRibData] = useState({
        "libelle":"",
        "iban":"",
        "password":""
    });
    const [updPass,setUpdPass] = useState({
        "password":"",
        "passNew":""
    })

    const [ribs,setRibs] = useState([]);
    const [error,setError] = useState();
    const [ribOne,setRibOne] = useState([]);
    const [countrieList,setCountrieList] = useState([]);


    useEffect(()=>{
        if(!can(user,'voir-page-profil')){
            navigate('/');
        }
        fetchRibList();
        fetchCountrieList();
    },[user]);

    const fetchRibList=async()=>{
        setLoading(true);
        try {
           await axios.get(url+'/rib/list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{
                setLoading(false);
                if(resp.status == 200)
                {
                    setRibs(resp.data.datas);
                }
                else
                {
                    console.log(resp.data.status);   
                }
            })
        } catch (error) {
            
        }
    }

    const fetchCountrieList = async ()=>{
        try {
            axios.get(url+'/countrie/list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{

                if(resp.status == 200)
                {
                    setCountrieList(resp.data.datas);
                }
                else
                {
                    console.log(resp.data.status);   
                }
            })
        } catch (error) {
            
        }
    }
    
    const handleChange = (e)=>{
        setRibData({
            ...ribData,
            [e.target.name]:e.target.value
        });
    }

    const handleChangePass = (e)=>{
        setUpdPass({
            ...updPass,
            [e.target.name]:e.target.value
        });
    }


    const openModalRib=()=>{
        setRibData({
            "libelle":"",
            "iban":"",
            "password":""
        });
        setError('');

        window.$('#modalrib').modal('show');
    }

    
    const openModalRibEdit=(rib)=>{
        setRibData({
            "libelle":rib.libelle,
            "iban":rib.iban,
            "password":""
        });
        setError('');
        setRibOne(rib);

        window.$('#modalribedit').modal('show');
    }

    const openModalRibdelete=(rib)=>{
        setRibOne(rib);

        setRibData({
            "libelle":rib.libelle,
            "iban":rib.iban,
            "password":""
        });
        setError('');
        window.$('#modalribdelete').modal('show');
    }

    const submitFormRib= (e)=>{
        e.preventDefault();

        const _formData = new FormData();
        _formData.append('libelle',ribData.libelle);
        _formData.append('iban',ribData.iban);
        _formData.append('password',ribData.password);

        // console.log(_formData);
        setLoadingform(true);
        try {
            axios.post(url+'/store/rib-marchand',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
               // console.log('ici');
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
                    //  fetchDataMarchands();
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
               fetchRibList();
               window.$('#modalrib').modal('hide');
           }).catch((error)=>{
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);

            //    console.log(error.response)
           })
       } catch (error) {
           console.log(error.response);
       } 

    }

    const submitFormEdit =(e)=>{
        e.preventDefault();

        const _formData = new FormData();
        _formData.append('libelle',ribData.libelle);
        _formData.append('iban',ribData.iban);
        _formData.append('password',ribData.password);
        _formData.append('id',ribOne.id);

        // console.log(_formData);
        setLoadingform(true);
        try {
            axios.post(url+'/update/rib-marchand',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
               // console.log('ici');
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
                    //  fetchDataMarchands();
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
               fetchRibList();
               window.$('#modalribedit').modal('hide');
           }).catch((error)=>{
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);

            //    console.log(error.response)
           })
       } catch (error) {
           console.log(error.response);
       } 
    }

    const submitFormdelete=(e)=>{
        e.preventDefault();

        const _formData = new FormData();

        _formData.append('password',ribData.password);
        _formData.append('id',ribOne.id);

        // console.log(_formData);
        setLoadingform(true);
        try {
            axios.post(url+'/delete/rib-marchand',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
               // console.log('ici');
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
                    //  fetchDataMarchands();
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
               fetchRibList();
               window.$('#modalribdelete').modal('hide');
           }).catch((error)=>{
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);

            //    console.log(error.response)
           })
       } catch (error) {
           console.log(error.response);
       } 

    }

    const submitEditPass=(e)=>{
        e.preventDefault();

        const _formData = new FormData();

        _formData.append('password',updPass.password);
        _formData.append('passNew',updPass.passNew);

        setLoadingform(true);
        try {
            axios.post(url+'/update/pass-marchand',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
               // console.log('ici');
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
                    //  fetchDataMarchands();
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
               setUpdPass({
                "passNew":"",
                "password":""
               })
           }).catch((error)=>{
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);

            //    console.log(error.response)
           })
       } catch (error) {
           console.log(error.response);
       }

    }




    return (
        <>
            <ContentPrincipal navactive={"profil"} ulShownav={"parametrage"}>
            
            <section class="section">
            <div class="row">
                <div class="col-lg-8">

                <div class="card">
                    <div class="card-body">
                    <h5 class="card-title">Information marchand</h5>

                    
                    <form class="row g-3">
                        <div class="col-md-6">
                        <label for="inputName5" class="form-label">Nom</label>
                        <input type="text" class="form-control" id="inputName5" value={user?.nom} disabled/>
                        </div>
                        <div class="col-md-6">
                        <label for="inputName5" class="form-label">Prénoms</label>
                        <input type="text" class="form-control" id="inputName5"  value={user?.prenom} disabled/>
                        </div>
                        <div class="col-md-4">
                        <label for="inputEmail5" class="form-label">Email</label>
                        <input type="email" class="form-control" id="inputEmail5" value={user?.email} disabled/>
                        </div>
                        <div class="col-md-4">
                        <label for="inputPassword5" class="form-label">Téléphone</label>
                        <input type="text" class="form-control" id="inputPassword5" value={user?.tel} disabled/>
                        </div>
                        {/* <div class="col-4">
                        <label for="inputAddress5" class="form-label">Pays</label>
                        <select id="inputState" class="form-select" value={user?.countrie_id} disabled>
                     
                            <option value="">...</option>
                            {countrieList?.map((countrie,index)=>
                                <option value={countrie.id}>{countrie.libelle}</option>
                            )}
                        </select>
                        </div> */}
                        <div class="col-12">
                        <label for="inputAddress2" class="form-label">Entreprise</label>
                        <input type="text" class="form-control" id="inputAddress2" value={user?.entreprise?.raison_social} disabled/>
                        </div>
                       
                        {/* <div class="text-center">
                        <button type="submit" class="btn btn-primary form-control">MODIFIER</button>
                        </div> */}
                    </form>

                    </div>
                </div>

                     <div class="card">
                     <div class="card-body">
                     <h5 class="card-title">Versements RIB</h5>
 
                     
                     <div class="row g-3">
                         <table class="table table-striped">
                         <tbody>
                         {loading && 
                             <tr className="text-center">
                                 
                             <div class="spinner-border" role="status">
                                 <span class="visually-hidden">Loading...</span>
                             </div>
                             
                             </tr>
                         } 
                         {!loading && 
                             ribs?.map((rib,index)=>
                             <tr>
                                 <td><b>{rib.iban}</b></td>
                                 <td></td>
                                 <td></td>
                                 <td className="text-end">
                                     <button className="btn btn-primary btn-sm p-0" onClick={()=>openModalRibEdit(rib)}><i class="ri-ball-pen-fill"></i></button>
                                     <button className="btn btn-danger btn-sm mx-1 p-0" onClick={()=>openModalRibdelete(rib)}><i class="ri-delete-bin-5-fill"></i></button>
                                 </td>
                             </tr>
                             )
                         }
                             
                               
                         </tbody>
                         </table>
                        
                         <div class="text-center">
                         <button type="button" class="btn btn-primary form-control" onClick={openModalRib}>Ajouter</button>
                         </div>
                     </div>
 
                     </div>
                 </div>
               

                <div class="card">
                    <div class="card-body">
                    <h5 class="card-title">Mot de passe</h5>

                    
                    <form class="row g-3" onSubmit={submitEditPass}>
                      
                        <div class="col-md-6">
                        <label for="inputEmail5" class="form-label">Mot de passe actuel</label>
                        <input type="password" class="form-control" id="inputEmail5" name="password" onChange={handleChangePass} required/>
                        {errors && errors.password && (
                            <span className="text-danger">{errors.password[0]}</span>
                        )}
                        {error && <span className="text-danger">{error}</span>}
                        </div>
                        <div class="col-md-6">
                        <label for="inputPassword5" class="form-label">Nouveau mot de passe</label>
                        <input type="password" class="form-control" id="inputPassword5" name="passNew" onChange={handleChangePass} />
                        {errors && errors.passNew && (
                            <span className="text-danger">{errors.passNew[0]}</span>
                        )}
                        </div>
                       
                       
                        <div class="text-center">
                            <button type="submit" class="btn btn-primary form-control">MODIFIER</button>
                        </div>
                    </form>

                    </div>
                </div>

                </div>

                <div class="col-lg-4">
                {/* {user && user?.rUser == 0 && */}
                <div class="card">
                    <div class="card-body">
                    <h5 class="card-title">Intégration</h5>

                
                    <div class="row g-2">
                        <div class="col-12">
                        <label for="inputNanme4" class="form-label">Secret Key </label>
                        <input type="text" class="form-control" id="inputNanme4" value={user?.entreprise?.secretKey} disabled/>
                        </div>
                        <div class="col-12">
                        <label for="inputEmail4" class="form-label">APIKEY</label>
                        <input type="text" class="form-control" id="inputEmail4" value={user?.entreprise?.apiKey} disabled/>
                        </div>
                        <div class="col-12">
                        <label for="inputPassword4" class="form-label">SITE ID</label>
                        <input type="text" class="form-control" id="inputPassword4" value={user?.entreprise?.siteID} disabled/>
                        </div>
                        {/* <div class="col-12">
                        <label for="inputAddress" class="form-label">Address</label>
                        <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" />
                        </div> */}
                       
                    </div>

                    </div>
                </div>
                {/* } */}

                

                </div>
            </div>
            </section>

            <div class="modal fade" id="modalrib" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Ajouter un rib</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form class="row g-1" onSubmit={submitFormRib}> 
                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">Libelle<span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="libelle" onChange={handleChange} value={ribData.libelle} required/>
                                        {errors && errors.libelle && (
                                            <span className="text-danger">{errors.libelle[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">IBAN <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="iban" onChange={handleChange} value={ribData.iban} required/>
                                        {errors && errors.iban && (
                                            <span className="text-danger">{errors.iban[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputEmail5" class="form-label">Saisissez votre mot de passe <span className="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="inputEmail5" name="password" onChange={handleChange} value={ribData.password} required/>
                                        {errors && errors.password && (
                                            <span className="text-danger">{errors.password[0]}</span>
                                        )}
                                        {error && <span className="text-danger">{error}</span>}
                                    </div>
                           
                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        {loadingform && 
                                        <button class="btn btn-primary" type="button" disabled="">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        En chargement...
                                      </button>}
                                        {!loadingform && <button type="submit" class="btn btn-primary">Enregistrer</button>}
                                        
                                        </div>
                                </form>
                            </div>
                           
                        </div>
                        </div>
            </div>

            <div class="modal fade" id="modalribedit" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Information rib</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form class="row g-1" onSubmit={submitFormEdit}> 
                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">Libelle<span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="libelle" onChange={handleChange} value={ribData.libelle} required/>
                                        {errors && errors.libelle && (
                                            <span className="text-danger">{errors.libelle[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">IBAN <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="iban" onChange={handleChange} value={ribData.iban} required/>
                                        {errors && errors.iban && (
                                            <span className="text-danger">{errors.iban[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputEmail5" class="form-label">Saisissez votre mot de passe <span className="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="inputEmail5" name="password" onChange={handleChange} value={ribData.password} required/>
                                        {errors && errors.password && (
                                            <span className="text-danger">{errors.password[0]}</span>
                                        )}
                                        {error && <span className="text-danger">{error}</span>}
                                    </div>
                           
                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        {loadingform && 
                                        <button class="btn btn-primary" type="button" disabled="">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        En chargement...
                                      </button>}
                                        {!loadingform && <button type="submit" class="btn btn-success">Modifier</button>}
                                        
                                        </div>
                                </form>
                            </div>
                           
                        </div>
                        </div>
            </div>

            <div class="modal fade" id="modalribdelete" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Etes-vous sûre de vouloir supprimer cet rib ?</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form class="row g-1" onSubmit={submitFormdelete}> 
                                   <div class="col-md-12">
                                        <label for="inputName5" class="form-label">Libelle</label>
                                        <input type="text" class="form-control" id="inputName5" value={ribData.libelle} disabled/>
                                        
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">IBAN </label>
                                        <input type="text" class="form-control" id="inputName5"  value={ribData.iban} disabled/>
                                       
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputEmail5" class="form-label">Saisissez votre mot de passe <span className="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="inputEmail5" name="password" onChange={handleChange} value={ribData.password} required/>
                                        {errors && errors.password && (
                                            <span className="text-danger">{errors.password[0]}</span>
                                        )}
                                        {error && <span className="text-danger">{error}</span>}
                                    </div>
                           
                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        {loadingform && 
                                        <button class="btn btn-primary" type="button" disabled="">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        En chargement...
                                      </button>}
                                        {!loadingform && <button type="submit" class="btn btn-danger">Supprimé</button>}
                                        
                                        </div>
                                </form>
                            </div>
                           
                        </div>
                        </div>
            </div>
            </ContentPrincipal>
        </>
    )
}

export default Profil;