import { useEffect, useState ,createContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import BaseUrl from "../BaseUrl";

const url = BaseUrl();

const UserContext = createContext();



const UserProvider = ({ children }) => {

    const [user,setUsers] = useState(null);
    const navigate = useNavigate();

    useEffect(()=>{
        if(localStorage.getItem('_token_') !== null){
            try {
                axios.get(url+'/me',
            {
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
                // credentials:'include'
            }
                ).then((resp)=>{
                    if(resp.status == 200){
                        if(resp.data.error){
                            localStorage.removeItem('_token_');
                            navigate('/');
                        }
                        setUsers(resp.data);
                    }else{
                        localStorage.removeItem('_token_');
                        navigate('/');
                    }
                })
            } catch (error) {
                console.log(error.response.status);
               
            }
            
        }else{
            localStorage.removeItem('_token_');
            navigate('/');
        }


    },[]);


    return (
        <UserContext.Provider value={{ user, setUsers }}>
            {children}
        </UserContext.Provider>
    );

}

export { UserContext, UserProvider };
