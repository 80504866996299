import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const ExportExcel = ({excelData, filename}) => {
    // const ws = XLSX.utils.json_to_sheet(excelData)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel=async()=>{
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, filename + fileExtension);
    }


    return (
        <button type="button" class="btn btn-success float-end" onClick={(e)=>exportToExcel(filename)}>
            <i class="bi bi-star me-1"></i> Export Excel
        </button>
    )
}

export default ExportExcel;