import { useContext, useEffect, useState } from "react";
import ContentPrincipal from "../Content";
import axios from "axios";
import BaseUrl from "../BaseUrl";
import Swal from "sweetalert2";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../auth/Usercheck";
// import TransactionExportPdf from "./pdf/TransactonPdf";
import ExportExcel from "../ExcelExport";
import can from "../auth/CanUser";
// import UserExportPdf from "./pdf/UtilisateurPdf";

 

const url = BaseUrl();
function Utilisateur()
{
    const {user, setUsers} = useContext(UserContext);
    const navigate = useNavigate();

    const [errors,setErrors] = useState({});
    const [loadingform,setLoadingform] = useState(false);
    const [loading, setLoading] = useState();
    const [marchands,setMarchands] = useState([]);
    const [marchand,setMarchand] = useState(null);
    const [error,setError] = useState();
    const [countrieList,setCountrieList] = useState([]);
    const [indexLoading,setIndexLoading] = useState(null);


    const [currentPage,setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records =marchands?.length >0 ? marchands.slice(firstIndex, lastIndex) : [];
    const npage =marchands?.length >0 ? Math.ceil(marchands.length / recordsPerPage):0;
    const numbers = [...Array(npage + 1).keys()].slice(1);

    const [marchanData,setMarchanData] = useState({
        "nom":"",
        "prenom":"",
        "tel":"",
        "email":"",
        "entreprise_id":"",
    });

    // useEffect(()=>{
    //     if(localStorage.getItem('_token_') == null){
         
    //       localStorage.removeItem('_token_');
    //       navigate('/');
    
    //   }
    //   },[user]);

    useEffect(()=>{
        if(!can(user,'voir-page-utilisateur')){
            navigate('/');
        }
        fetchDataMarchands();
        fetchCountrieList();
        // if(user && user?.rUser !== 1)
        // {
        //     navigate('/dashboard')
        // }
    },[user]);

    const fetchDataMarchands=async()=>{
        setLoading(true);
        try {
            axios.get(url+'/users/all',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{
                setLoading(false);
                if(resp.status == 200)
                {
                    setMarchands(resp.data.datas);
                }
            })
        } catch (error) {
            
        }
    }

    const fetchCountrieList = async ()=>{
        try {
            axios.get(url+'/all/marchands',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{

                if(resp.status == 200)
                {
                    setCountrieList(resp.data.datas);
                }
                else
                {
                    console.log(resp.data.status);   
                }
            })
        } catch (error) {
            
        }
    }


    const handleChange = (e)=>{
        setMarchanData({
            ...marchanData,
            [e.target.name]:e.target.value
        });
    }

    const submitForm= (e)=>{
        e.preventDefault();
  
        const _formData = new FormData();
        _formData.append('nom',marchanData.nom);
        _formData.append('prenom',marchanData.prenom);
        _formData.append('tel',marchanData.tel);
        _formData.append('email',marchanData.email);
        _formData.append('entreprise_id',marchanData.entreprise_id);

        setLoadingform(true);
        try {
             axios.post(url+'/users/store',_formData,
            {
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
                credentials:'include'
            }
            ).then((resp)=>{
                // console.log('ici');
                setLoadingform(false);
                if(resp.status == 200)
                {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: resp.data.message,
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                      fetchDataMarchands();
                      setMarchanData({
                        "nom":"",
                        "prenom":"",
                        "tel":"",
                        "email":"",
                        "entreprise_id":"",
                    })
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title:  resp.data.message,
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                }
                window.$('#basicModal').modal('hide');
            }).catch((error)=>{
                setLoadingform(false);
                setError(error.response.data.message);
                setErrors(error.response.data.error);
            })
        } catch (error) {
            console.log(error);
        } 




    }

    const openModaladd =()=>{
        setMarchanData({
            "nom":"",
            "prenom":"",
            "tel":"",
            "email":"",
            "entreprise_id":"",

        })
        setError('');
        window.$('#basicModal').modal('show');
    }

    const openModalView =(marchand)=>{
        setMarchand(marchand);
        window.$('#basicModalsee').modal('show');
    }

    const openModalEdit = (marchand)=>{
        setMarchand(marchand);

        setMarchanData({
            "nom":marchand.nom,
            "prenom":marchand.prenom,
            "tel":marchand.tel,
            "email":marchand.email,
            "entreprise_id":marchand?.entreprise_id,

        })
        window.$('#basicModal').modal('show');
    }


    const submitFormEdit = (e)=>{
        e.preventDefault();

        const _formData = new FormData();
        _formData.append('nom',marchanData.nom);
        _formData.append('prenom',marchanData.prenom);
        _formData.append('tel',marchanData.tel);
        _formData.append('email',marchanData.email);
        _formData.append('entreprise_id',marchanData.entreprise_id);
        _formData.append('id',marchand.id);

        // console.log(_formData);

        try {
            axios.post(url+'/users/update',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
               // console.log('ici');
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
                     fetchDataMarchands();
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
               window.$('#basicModal').modal('hide');
           }).catch((error)=>{
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);
           })
       } catch (error) {
           console.log(error);
       } 
    
    }

    function prePage()
    {
        if(currentPage !== 1)
        {
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage()
    {
        if(currentPage !== npage)
        {
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(n)
    {
       setCurrentPage(n);
    }

    const deleteMarchand=(id)=>{
        Swal.fire({
            title: 'ATTENTION !',
            text: 'Êtes-vous sûre de vouloir changer le statut.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText:"NON",
            confirmButtonText: 'OUI',
            cancelButtonColor:"red"
          }).then((result) => {
            if (result.isConfirmed) {
                try {
                    axios.get(url+'/users/delete/?id='+id,
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                        }
                    ).then((resp)=>{
                        if(resp.status == 200)
                        {
                            Swal.fire(
                                'Supprimé',
                                resp.data.message,
                                'success'
                              )
                        }else{
                            Swal.fire(
                                'Attention',
                                resp.data.message,
                                'error'
                              )
                        }
                        fetchDataMarchands();
                    })
                } catch (error) {
                    console.log(error);
                }

            
            }
          }); 
    }

    const reset_password=(id)=>{
       
        Swal.fire({
            title: 'ATTENTION !',
            text: 'Cette action va reinitialiser le mot de passe.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText:"NON",
            confirmButtonText: 'OUI',
            cancelButtonColor:"red"
          }).then((result) => {
            if (result.isConfirmed) {
                setIndexLoading(id);
                try {
                    axios.get(url+'/users/reset-password/?id='+id,
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                        }
                    ).then((resp)=>{
                        if(resp.status == 200)
                        {
                            Swal.fire(
                                'Succès',
                                resp.data.message,
                                'success'
                              )
                        }else{
                            Swal.fire(
                                'Attention',
                                resp.data.message,
                                'error'
                              )
                        }
                        setIndexLoading(null);
                        fetchDataMarchands();
                    })
                } catch (error) {
                    setIndexLoading(null);
                    console.log(error);
                }

            
            }
          }); 
    }

    const searchDataTrans=async(e)=>{
        var search = e.target.value;
   
            try {
                await  axios.post(url+'/search/marchands',{search:search},
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                      // credentials:'include'
                  }
                  ).then((resp)=>{
                    //   setLoadingData(true);
                      if(resp.status == 200)
                      {
                        setMarchands(resp.data.data);
                      }
                  })
              } catch (error) {
                  console.log(error);
              }
       
    }



    return (
        <>
            <ContentPrincipal navactive={"users"} ulShownav={""}>
            <div class="pagetitle mb-3">
                    <h1>Utilisateurs {can(user,"ajouter-utilisateur") && <button type="button" class="btn btn-primary btn-sm float-end" onClick={openModaladd}>Ajouter un nouvel utilisateur</button>} </h1>
                    
                    </div>

                    <section class="section">
                    <div class="row">
                        <div class="col-lg-12">

                        <div class="card">
                            <div class="card-body">
                            <h5 class="card-title">Liste des Utilisateurs
                            <ExportExcel excelData={marchands} filename={'Export utilisateurs Xls'}/>
                            {/* <UserExportPdf pdfData={marchands} /> */}
                            </h5>
                            <div class="card-header row g-4">
                        <div class="col-md-2">
                           
                        </div>
                        <div class="col-md-4">
                            
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <input type="search" placeholder="Rechercher..." class="form-control" name="search" id="search" onChange={searchDataTrans}/>
                            </div>
                        </div>
                    </div>
                            <table class="table datatable">
                                <thead>
                                <tr>
                                    <th>
                                    Date
                                    </th>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Email</th>
                                    <th>Téléphone</th>
                                    <th>Entreprise</th>
                                    <th>Statut</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {loading && 
                                      <tr className="text-center">
                                          
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                      
                                      </tr>
                                    }
                                    {!loading &&
                                        records?.map((marchand,index)=>
                                        <tr>
                                            <td>{moment(marchand.created_at).format("Do MMMM YYYY")}</td>
                                            <td>{marchand.nom}</td>
                                            <td>{marchand.prenom}</td>
                                            <td>{marchand.email}</td>
                                            <td>{marchand.tel}</td>
                                            <td>{marchand?.entreprise?.raison_social}</td>
                                            <td>
                                                {marchand.is_active == 1 && <span class="badge bg-success">active</span>}
                                                {marchand.is_active == 0 && <span class="badge bg-danger">desactive</span>}
                                            </td>
                                            <td>
                                                {indexLoading !== marchand.id &&

                                                    <>
                                                <button className="btn btn-secondary btn-sm mx-1" onClick={()=>reset_password(marchand.id)}><i class="ri-repeat-fill"></i></button>
                                               
                                               
                                               {can(user,"modifier-utilisateur") &&  <button className="btn btn-primary btn-sm" onClick={()=>openModalEdit(marchand)}><i class="ri-ball-pen-fill"></i></button>} 
                                               {can(user,"desactiver-utilisateur") && <button className={marchand.is_active == 1 ? "btn btn-danger btn-sm mx-1" : "btn btn-success btn-sm mx-1"} onClick={()=>deleteMarchand(marchand.id)} ><i class="ri-play-fill"></i></button>}
                                                    </>
                                                    
                                                }

                                            {indexLoading == marchand.id && <span class="badge bg-secondary">En cours...</span>}
                                              
                                                
                                            </td>
                                        </tr>
                                        )
                                    }
                                    
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation example" className="float-end">
                                <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link"   onClick={prePage}>
                                    <span aria-hidden="true">«</span>
                                    </a>
                                </li>
                                {numbers.map((n,i)=>
                                    <li class={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                        <a class="page-link"  onClick={()=>changePage(n)}>{n}</a>
                                    </li>
                                )}
                                

                                <li class="page-item">
                                    <a class="page-link" onClick={nextPage}>
                                    <span aria-hidden="true">»</span>
                                    </a>
                                </li>
                                </ul>
                            </nav>

                            </div>
                        </div>

                        </div>
                    </div>
                    </section>

                    <div class="modal fade" id="basicModal" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">{marchand==null ? 'Enregistrer un utilisateur' : 'Modifier'}{error && <span className="text-danger">{error}</span>}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form class="row g-1" onSubmit={marchand==null ? submitForm : submitFormEdit}> 
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Nom <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="nom" onChange={handleChange} value={marchanData.nom}/>
                                        {errors && errors.nom && (
                                            <span className="text-danger">{errors.nom[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Prenoms <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="prenom" onChange={handleChange} value={marchanData.prenom}/>
                                        {errors && errors.prenom && (
                                            <span className="text-danger">{errors.prenom[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Email <span className="text-danger">*</span></label>
                                        <input type="email" class="form-control" id="inputEmail5" name="email" onChange={handleChange} value={marchanData.email}/>
                                        {errors && errors.email && (
                                            <span className="text-danger">{errors.email[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Téléphone <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputEmail5" name="tel" onChange={handleChange} value={marchanData.tel}/>
                                        {errors && errors.tel && (
                                            <span className="text-danger">{errors.tel[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                            <label for="inputState" class="form-label">Entreprise <span className="text-danger">*</span></label>
                                            <select id="inputState" class="form-select" name="entreprise_id" onChange={handleChange} value={marchanData.entreprise_id}>
                                                <option value="">...</option>
                                                {countrieList?.map((entreprise,index)=>
                                                        <option value={entreprise.id}>{entreprise.raison_social}</option>
                                                    )}
                                            </select>
                                        {errors && errors.entreprise_id && (
                                            <span className="text-danger">{errors.entreprise_id[0]}</span>
                                        )}
                                    </div>
                                  
                                  
                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        {loadingform && 
                                        <button class="btn btn-primary" type="button" disabled="">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        En chargement...
                                      </button>}
                                        {!loadingform && <button type="submit" class="btn btn-primary">Enregistrer</button>}
                                        
                                        </div>
                                </form>
                            </div>
                           
                        </div>
                        </div>
                    </div>

                    {/* <div class="modal fade" id="basicModalsee" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header bg-info">
                            <h5 class="modal-title">Detail marchand</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row g-1" > 
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Nom du marchand</label>
                                        <input type="text" class="form-control" id="inputName5" name="nom"  value={marchand.nom} disabled/>
                                       
                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Prenoms </label>
                                        <input type="text" class="form-control" id="inputName5" name="prenom"  value={marchand.prenom} disabled/>
                                   
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Email </label>
                                        <input type="email" class="form-control" id="inputEmail5" name="email"  value={marchand.email} disabled/>
                                      
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Téléphone </label>
                                        <input type="text" class="form-control" id="inputEmail5" name="tel"  value={marchand.tel} disabled/>
                                        
                                    </div>
                                    <div class="col-md-4">
                                            <label for="inputState" class="form-label">Pays </label>
                                            <select id="inputState" class="form-select" name="countrie_id" value={marchand.countrie_id} disabled>
                                                <option value="">...</option>
                                                {countrieList?.map((countrie,index)=>
                                                        <option value={countrie.id}>{countrie.libelle}</option>
                                                    )}
                                            </select>
                                       
                                    </div>
                                    <hr />
                                    <div class="col-6">
                                        <label for="inputAddress5" class="form-label">Entreprise </label>
                                        <input type="text" class="form-control" id="inputAddres5s" name="entreprise"  value={marchand.entreprise} disabled/>
                                       
                                    </div>
                                    <div class="col-6">
                                        <label for="inputAddress5" class="form-label">Solde disponible <b>(F CFA)</b></label>
                                        <input type="text" class="form-control text-danger fw-bold" id="inputAddres5s" name="solde"  value={marchand.solde} disabled/>
                                       
                                    </div>
                                        <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Address</label>
                                            <input type="text" class="form-control" id="inputAddress2" name="adresse"  value={marchand.adresse} disabled/>
                                        </div>
                                        <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Contact</label>
                                            <input type="text" class="form-control" id="inputAddress2" name="contactPro"  value={marchand.contactPro} disabled/>
                                        </div>
                                       
                                        <hr />
                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">SITE ID IMONEY</label>
                                            <input type="text" class="form-control" id="inputCity" name="siteId_imoney"  value={marchand.siteId_imoney} disabled/>
                                        </div>
                                     

                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">Secret Key iMONEY </label>
                                            <input type="text" class="form-control" id="inputCity" name="secretKey_imoney"  value={marchand.secretKey_imoney} disabled/>

                                        </div>
                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">APIKEY IMONEY</label>
                                            <input type="text" class="form-control" id="inputCity" name="apikey_imoney" value={marchand.apikey_imoney} disabled/>

                                        </div>

                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">SITE ID DISTRIPAY</label>
                                            <input type="text" class="form-control" id="inputCity" name="siteId_imoney"  value={marchand.siteID} disabled/>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">Secret Key DISTRIPAY</label>
                                            <input type="text" class="form-control" id="inputCity" name="siteId_imoney"  value={marchand.secretKey} disabled/>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">APIKEY DISTRIPAY</label>
                                            <input type="text" class="form-control" id="inputCity" name="siteId_imoney"  value={marchand.apiKey} disabled/>
                                        </div>

                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                       
                                        
                                        </div>
                                </div>
                            </div>
                           
                        </div>
                        </div>
                    </div> */}


            </ContentPrincipal>
        </>
    )
}

export default Utilisateur;