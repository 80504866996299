import BaseUrl from "../BaseUrl";
import { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";


function Login() {
    const url = BaseUrl();
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_'));
    const [msgerrorAlert,setMsgerrorAlert] = useState('');
    const navigate = useNavigate();
    const [loginData,setLoginData] = useState({
        'email':'',
        'password':''
    });
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        if(isAuthToken !== null){
            navigate('/dashboard/');
        }
    },[isAuthToken]);

    const handleChange=(event)=>{
        setLoginData({
            ...loginData,
            [event.target.name] : event.target.value
        });
    }

    const submitLogin=async (e)=>{
        e.preventDefault()

        setMsgerrorAlert('');
        
        if(loginData.email !== "" && loginData.password !==""){
            const _formData = new FormData();
            _formData.append('email',loginData.email);
            _formData.append('password',loginData.password);
            // console.log(_formData);

            setLoading(true);
            try {
                await axios.post(url+'/login/',_formData,{
                    headers:{
                        'Content-Type':'application/json',
                        "Authorization": `Bearer ${isAuthToken}`
                        
                    },
                    credentials:'include'
                }
                ).then((resp)=>{
                    setLoading(false);
                    if(resp.status == 200){                  
                        localStorage.setItem('_token_',resp.data.authorisation.token);
                        navigate('/dashboard/');
                        window.location.reload();
                    }
                })
            } catch (error) {
                setLoading(false);
                if (error.response.status == 401) {
                    setMsgerrorAlert(error.response.data.error);
                }else{
                    setMsgerrorAlert("Un problème est subvenu !");
                }
            }
        }
    }


    return (
        <>
             <main>
                <div class="container">

                <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                        <div class="d-flex justify-content-center py-4">
                            <a href="index.html" class="logo d-flex align-items-center w-auto">
                            <img src="/assets/img/logo.png" alt="" />
                            <span class="d-none d-lg-block">DISTRIPAY</span>
                            </a>
                        </div>

                        <div class="card mb-3">

                            <div class="card-body">

                            <div class="pt-4 pb-2">
                                {msgerrorAlert !=="" &&
                                <div class="alert alert-danger" role="alert">
                                    <h4 class="alert-heading">Attention !</h4>
                                    <p>{msgerrorAlert}</p>
                                </div>
                                }
                                <h5 class="card-title text-center pb-0 fs-4">CONNECTEZ-VOUS A VOTRE COMPTE</h5>
                                {/* <p class="text-center small">Enter your username & password to login</p> */}
                            </div>

                            <form onSubmit={submitLogin}>

                                <div class="col-12">
                                <label for="yourUsername" class="form-label">Email</label>
                                <div class="input-group ">
                                    <span class="input-group-text" id="inputGroupPrepend">@</span>
                                    <input type="email" name="email" onChange={handleChange} class="form-control" required />
                                    {/* <div class="invalid-feedback">Please enter your username.</div> */}
                                </div>
                                </div>

                                <div class="col-12 mb-3">
                                <label for="yourPassword" class="form-label">Mot de passe</label>
                                <input type="password" name="password" onChange={handleChange} class="form-control" required />
                                {/* <div class="invalid-feedback">Please enter your password!</div> */}
                                </div>

                                {/* <div class="col-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                    <label class="form-check-label" for="rememberMe">Remember me</label>
                                </div>
                                </div> */}
                                <div class="col-12">
                                <button class={!loading ? "btn btn-primary w-100" : "btn btn-primary w-100 disabled"} type="submit">{!loading ? "CONNECTEZ-VOUS" : "En chargement..."}</button>
                                </div>
                                <div class="col-12">
                                {/* <p class="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p> */}
                                </div>
                            </form>

                            </div>
                        </div>

                        {/* <div class="credits">
                            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                        </div> */}

                        </div>
                    </div>
                    </div>

                </section>

                </div>
            </main>
        </>
    )
}

export default Login;