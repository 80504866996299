export default function CustomerStyle(){
    const customStyles = {
        headRow: {
            style: {
                backgroundColor: 'blue',
                color: 'white'
            }
        },
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'uppercase'
            }
        },
        cells: {
            style: {
                fontSize: '15px'
            }
        }
    };

    return customStyles;
}