import ContentPrincipal from "../Content";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BaseUrl from "../BaseUrl";
import { UserContext } from "../auth/Usercheck";
import ExportExcel from "../ExcelExport";
import TransactionExportPdf from "./pdf/TransactonPdf";
import ReversementExportPdf from "./pdf/ReversementPdf";
import can from "../auth/CanUser";

const url = BaseUrl();

function Reversement()
{
    const {user,setUsers} = useContext(UserContext);
    const navigate = useNavigate();
    const [errors,setErrors] = useState({});
    const [loadingform,setLoadingform] = useState(false);
    const [loading, setLoading] = useState(false);
    const [soldeDisponible,setSoldeDisponible] = useState();
    const [errorMontant,setErrorMontant] = useState('');
    const [error,setError] = useState();
    const [confirmCode,setConfirmCode]= useState(null);
    const [actifReversement,setActifReversement] = useState(null);
    const [traitementData,setTraitementData] = useState({
        "status":"",
        "commentaire":"",
        "password":""
    })

    const [reversementData,setReversementData] = useState({
        "countrie_id":"",
        "rib_id":"",
        "montant":"",
        "password":""
    });
    const [ribLists,setRibLists] = useState([]);
    const [countrieList,setCountrieList] = useState([]);
    const [reversements,setReversements] = useState([]);
    const [reverse,setReverse] = useState([]);


    const [currentPage,setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records =reversements?.length > 0 ? reversements.slice(firstIndex, lastIndex) : [];
    const npage =reversements?.length > 0 ? Math.ceil(reversements.length / recordsPerPage) : 0;
    const numbers = [...Array(npage + 1).keys()].slice(1);


    useEffect(()=>{
        if(!can(user,'voir-page-reversement')){
            navigate('/');
        }
        fetchRibList();
        fetchCountrieList();
        fetchDataReversements();
    },[user]);

    const fetchDataReversements=async()=>{
        setLoading(true);
        try {
            axios.get(url+'/reversements/list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{
                setLoading(false);
                if(resp.status == 200)
                {
                    setReversements(resp.data.datas);
                    setActifReversement(resp.data.actif);
                }
                else
                {
                    console.log(resp.data.status);   
                }
            })
        } catch (error) {
            
        }
    }

    const searchDataTrans=async(e)=>{
        var search = e.target.value;
   
            try {
                await  axios.post(url+'/reversements/search',{search:search},
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                      // credentials:'include'
                  }
                  ).then((resp)=>{
                    //   setLoadingData(true);
                      if(resp.status == 200)
                      {
                        setReversements(resp.data.data);
                      }
                  })
              } catch (error) {
                  console.log(error);
              }
       
    }


    const fetchRibList = async ()=>{
        try {
            axios.get(url+'/rib/list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{

                if(resp.status == 200)
                {
                    setRibLists(resp.data.datas);
                }
                else
                {
                    console.log(resp.data.status);   
                }
            })
        } catch (error) {
            
        }
    }

    const fetchCountrieList = async ()=>{
        try {
            axios.get(url+'/countrie/list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{

                if(resp.status == 200)
                {
                    setCountrieList(resp.data.datas);
                }
                else
                {
                    console.log(resp.data.status);   
                }
            })
        } catch (error) {
            
        }
    }

    const handleChange = (e)=>{

        if(e.target.name == "rib_id"){
            try {
              axios.post(url+'/solde/disponibles-montant-transaction',{},
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                      // credentials:'include'
                  }
                  ).then((resp)=>{
                    // setLoading(false);
                      if(resp.status == 200)
                      {
                         setSoldeDisponible(resp.data.data);
                      }
                  })
              } catch (error) {
                  console.log(error);
              }
        }

        if(e.target.name == "montant"){
            if(e.target.value > soldeDisponible)
            {
                setErrorMontant('montant supperieur');
            }else{
                setErrorMontant('');
            }

            if(e.target.value < 0)
            {
                setErrorMontant('montant inferieur à 0');
            }
        }
        setReversementData({
            ...reversementData,
            [e.target.name]:e.target.value
        });
    }

    const handleChangeTraitement=(e)=>{
        setTraitementData({
            ...traitementData,
            [e.target.name]:e.target.value
        });
    }

    const submitForm= (e)=>{
        e.preventDefault();

        const _formData = new FormData();
        _formData.append('countrie_id',reversementData.countrie_id);
        _formData.append('rib_id',reversementData.rib_id);
        _formData.append('montant',reversementData.montant);
        _formData.append('password',reversementData.password);

        // console.log(_formData);
        
        if(errorMontant == ""){
            setLoadingform(true);
            try {
                axios.post(url+'/store/reversement',_formData,
               {
                   headers:{
                       'Content-Type':'application/json',
                       "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                       
                   },
                   credentials:'include'
               }
               ).then((resp)=>{
                   // console.log('ici');
                   setLoadingform(false);
                   if(resp.status == 200)
                   {
                    localStorage.setItem('idReversement',resp.data.id);
                    window.$('#basicModal').modal('hide');
                    window.$('#basicModalconfirm').modal('show');
                    setErrors({});
                   }else{
                       Swal.fire({
                           position: 'top-end',
                           icon: 'error',
                           title:  resp.data.message,
                           showConfirmButton: false,
                           timer: 3000,
                           toast:true,
                           position:'top-right',
                           timerProgressBar:true
                         });
                   }
                //    fetchRibList();
                 
               }).catch((error)=>{
                   setLoadingform(false);
                   setError(error.response.data.message);
                   setErrors(error.response.data.error);
    
                //    console.log(error.response)
               })
           } catch (error) {
               console.log(error.response);
           } 
        }


    }

    const submitFormConfirm =(e)=>{
        e.preventDefault();
        const _formData = new FormData();
        _formData.append('code',confirmCode);
        _formData.append('reversement_id',localStorage.getItem('idReversement'));

        setLoadingform(true);
        try {
            axios.post(url+'/confirm/reversement',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
               // console.log('ici');
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
                    //  fetchDataMarchands();
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
               localStorage.removeItem('idReversement');
               fetchDataReversements();
               window.$('#basicModalconfirm').modal('hide');
           }).catch((error)=>{
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);

            //    console.log(error.response)
           })
       } catch (error) {
           console.log(error.response);
       } 

    }

    const openModaladd =()=>{
        if(actifReversement > 0)
        {
            Swal.fire({
                icon: "error",
                title: "Attention !",
                text: "Une demande de reversement est cours de traitement",
                // footer: '<a href="#">Why do I have this issue?</a>'
              });
        }else if(actifReversement == 0){
            setReversementData({
                "countrie_id":"",
                "rib_id":"",
                "montant":"",
                "password":""
            });
            setError('');
            setErrors({})
            setConfirmCode(null);
            localStorage.removeItem('idReversement');
            window.$('#basicModal').modal('show');
        }else{
            setError('');
            setErrors({});
            setConfirmCode(null);
            localStorage.removeItem('idReversement');
            window.$('#basicModal').modal('show');
        }

    }

    const openTraitementMoodel=(reverse)=>{
        setReverse(reverse);
        setTraitementData({
            "status":"",
            "commentaire":"",
            "password":""
        });
        window.$('#basicModalTraitement').modal('show');
    }
    

    function prePage()
    {
        if(currentPage !== 1)
        {
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage()
    {
        if(currentPage !== npage)
        {
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(n)
    {
       setCurrentPage(n);
    }
    
    const submitFormTraitement =(e)=>{
        e.preventDefault();

        const _formData = new FormData();
        _formData.append('status',traitementData.status);
        _formData.append('commentaire',traitementData.commentaire);
        _formData.append('password',traitementData.password);
        _formData.append('reversement_id',reverse.id);

        // console.log(_formData);
        setLoadingform(true);

        try {
            axios.post(url+'/traitement/reversement',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
               console.log('ici');
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
                    //  fetchDataMarchands();
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
                setReverse([]);
               fetchDataReversements();
               window.$('#basicModalTraitement').modal('hide');
           }).catch((error)=>{
            // console.log('ici1');
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);

            //    console.log(error.response)
           })
       } catch (error) {
           console.log(error.response);
       } 
    }

    return (
        <>
            <ContentPrincipal navactive={"reversement"} ulShownav={""}>
                    <div class="pagetitle mb-3">
                        <h1>Reversements {can(user,"ajouter-demande-reversement") && <button type="button" class="btn btn-primary btn-sm float-end" onClick={openModaladd}>DEMANDE DE REVERSEMENT</button>}   </h1>
                    
                    </div>

                    <section class="section">
                    <div class="row">
                        <div class="col-lg-12">

                        <div class="card">
                            <div class="card-body">
                            <h5 class="card-title">Liste des demandes 
                            {can(user,"exporter-excel-pdf-reversement") && 
                                <>
                                    <ExportExcel excelData={reversements} filename={'Export reversement Xls'}/>
                                    <ReversementExportPdf pdfData={reversements} />
                                </>
                            }
                       
                            </h5>
                            <div class="card-header row g-4">
                        <div class="col-md-2">
                           
                        </div>
                        <div class="col-md-4">
                            
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <input type="search" placeholder="Rechercher..." class="form-control" name="search" id="search" onChange={searchDataTrans}/>
                            </div>
                        </div>
                    </div>
                            <table class="table datatable">
                                <thead>
                                <tr>
                                    <th>DATE</th>
                                    <th>ENTREPRISE</th>
                                    {/* <th>PAYS</th> */}
                                    <th>RIB</th>
                                    <th>MONTANT</th>
                                    <th>STATUT</th>
                                    <th>DATE TRAITEMENT</th>
                                    <th>COMMENTAIRE</th>
                                    <th className="text-center">ACTION</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {loading && 
                                      <tr className="text-center">
                                          
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                      
                                      </tr>
                                    } 
                                      {!loading &&
                                        records.map((reverse,index)=> 
                                        <tr>
                                            <td>{moment(reverse.created_at).format("Do MMMM YYYY")}</td>
                                            <td>{reverse?.entreprise?.raison_social}</td>
                                            {/* <td>{reverse.countrie?.libelle}</td> */}
                                            <td>{reverse.rib?.iban}</td>
                                            <td>{reverse.montant} <b>XOF</b></td>
                                            <td>
                                                {reverse.status == 1 && <span class="badge bg-success">Traité</span>}
                                                {reverse.status == 3 && <span class="badge bg-danger">Rejeté</span>}
                                                {reverse.status == 2 && <span class="badge bg-warning">en traitement</span>}
                                                {reverse.status == 0 && <span class="badge bg-info">en confirmation</span>}
                                            </td>
                                            <td> {reverse.date_traitement && moment(reverse.date_traitement).format("Do MMMM YYYY")}</td>
                                            <td>{reverse.commentaire}</td>
                                            <td className="text-center">
                                                {/* <button className="btn btn-secondary btn-sm mx-1" ><i class="ri-eye-line"></i></button> */}
                                                
                                                {reverse.status == 2 && can(user,"traite-reversement") &&
                                                    <button className="btn btn-primary btn-sm mx-1 " onClick={()=>openTraitementMoodel(reverse)}><i class="ri-ball-pen-fill"></i></button>
                                                }
                                                {/* {reverse.status == 0 && <button className="btn btn-danger btn-sm mx-1 p-0" ><i class="ri-delete-bin-5-fill"></i></button>} */}
                                            </td>
                                        </tr>
                                        ) 
                                    } 
                                    
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation example" className="float-end">
                                <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link"   onClick={prePage}>
                                    <span aria-hidden="true">«</span>
                                    </a>
                                </li>
                                {numbers.map((n,i)=>
                                    <li class={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                        <a class="page-link"  onClick={()=>changePage(n)}>{n}</a>
                                    </li>
                                )}
                                

                                <li class="page-item">
                                    <a class="page-link" onClick={nextPage}>
                                    <span aria-hidden="true">»</span>
                                    </a>
                                </li>
                                </ul>
                            </nav>

                            </div>
                        </div>

                        </div>
                    </div>
                    </section>

                   
                    <div class="modal fade" id="basicModal" tabindex="-1"  aria-hidden="true" >
                        <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Faire une demande reversement</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form class="row g-1" onSubmit={submitForm}> 
                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">Pays<span className="text-danger">*</span></label>
                                        <select id="inputState" class="form-select" name="countrie_id" onChange={handleChange} value={reversementData.countrie_id}>
                                                <option value="">...</option>
                                                {countrieList?.map((countrie,index)=>
                                                    <option value={countrie.id}>{countrie.libelle}</option>
                                                )}
                                                
                                            </select>
                                        {errors && errors.countrie_id && (
                                            <span className="text-danger">{errors.countrie_id[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">RIB <span className="text-danger">*</span></label>
                                        <select id="inputState" class="form-select" name="rib_id" onChange={handleChange} value={reversementData.rib_id}>
                                                <option value="">...</option>
                                                {ribLists?.map((rib,index)=>
                                                    <option value={rib.id}>{rib.iban}</option>
                                                )}
                                                
                                            </select>
                                        {errors && errors.rib_id && (
                                            <span className="text-danger">{errors.rib_id[0]}</span>
                                        )}
                                    </div>

                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Solde disponible (<b>XOF</b>)</label>
                                        <input type="text" class="form-control fw-bold" id="inputName5"  value={soldeDisponible} disabled/>                                     
                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Montant <span className="text-danger">* {errorMontant}</span></label>
                                        <input type="number" class="form-control text-bold" id="inputName5" name="montant" onChange={handleChange} value={reversementData.montant}/>
                                        {errors && errors.montant && (
                                            <span className="text-danger">{errors.montant[0]}</span>
                                        )}
                                    </div>

                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">Saisissez votre mot de passe <span className="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="inputName5" name="password" onChange={handleChange} value={reversementData.password}/>
                                        {errors && errors.password && (
                                            <span className="text-danger">{errors.password[0]}</span>
                                        )}
                                        {error && <span className="text-danger">{error}</span>}
                                    </div>

                                    {/* 
                                    <div class="card mt-2 pt-2 bg-warning">
                                        <div class="card-body">
                                        <div class="col-md-12">
                                            <label for="inputName5" class="form-label">Saisissez le code de confirmation </label>
                                            <input type="text" class="form-control text-bold " id="inputName5" name="montant" />
                                            
                                        </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="card">
                                        <div class="col-md-12 mt-2">
                                            <label for="inputName5" class="form-label">Montant </label>
                                            <input type="number" class="form-control text-bold " id="inputName5" name="montant" />
                                            
                                        </div>
                                    </div> */}
                                    
                                
                                
                                        
                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        {loadingform && 
                                        <button class="btn btn-primary" type="button" disabled="">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        En chargement...
                                      </button>}
                                        {!loadingform && <button type="submit" class="btn btn-primary" disabled={reversementData.montant > soldeDisponible | reversementData.montant == "" ? "disabled": ""}>Enregistrer</button>}
                                        
                                        </div>
                                </form>
                            </div>
                           
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="basicModalconfirm" tabindex="-1"  aria-hidden="true" data-bs-backdrop="static" role="dialog" data-bs-keyboard="false">
                        <div class="modal-dialog modal-md">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Confirmation reversement</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <form class="row g-1" onSubmit={submitFormConfirm}> 
                                        


                                        <div class="card mt-2 pt-2 bg-warning">
                                            <div class="card-body">
                                            <div class="col-md-12">
                                                <label for="inputName5" class="form-label">Saisissez le code de confirmation </label>
                                                <input type="text" class="form-control text-bold " id="inputName5" name="code" onChange={(e)=>setConfirmCode(e.target.value)} required/>
                                                {errors && errors.code && (
                                                    <span className="text-danger">{errors.code[0]}</span>
                                                )}
                                                {error && <span className="text-danger">{error}</span>}
                                            </div>
                                            </div>
                                        </div>
                                    
                                            
                                            <div class="modal-footer mt-3">
                                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button> */}
                                            {loadingform && 
                                            <button class="btn btn-primary" type="button" disabled="">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            En chargement...
                                        </button>}
                                            {!loadingform && <button type="submit" class="btn btn-primary form-control">Valider la demande</button>}
                                            
                                            </div>
                                    </form>
                                </div>
                            
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="basicModalTraitement" tabindex="-1"  aria-hidden="true" data-bs-backdrop="static" role="dialog" data-bs-keyboard="false">
                        <div class="modal-dialog modal-md">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Traitement reversement <b>{reverse.user?.entreprise?.raison_social}</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <form class="row g-1" onSubmit={submitFormTraitement}> 
                                        
                                    <div class="col-md-12">
                                        <label for="inputName5" class="form-label">Statut de traitement<span className="text-danger">*</span></label>
                                        <select id="inputState" class="form-select" name="status" onChange={handleChangeTraitement} value={traitementData.status}>
                                                <option value="">...</option>
                                                <option value="1">Traité avec succès</option>
                                                <option value="3">Demande rejetée</option>
                                            </select>
                                        {errors && errors.status && (
                                            <span className="text-danger">{errors.status[0]}</span>
                                        )}
                                    </div>

                                       
                                        <div class="col-md-12">
                                            <label for="inputName5" class="form-label">Commentaire <span className="text-danger">*</span></label>
                                            <textarea rows="3" class="form-control" id="inputName5" name="commentaire" value={traitementData.commentaire} onChange={handleChangeTraitement}></textarea>
                                            {errors && errors.commentaire && (
                                                <span className="text-danger">{errors.commentaire[0]}</span>
                                            )}
                                            {/* {error && <span className="text-danger">{error}</span>} */}
                                        </div>

                                        <div class="col-md-12">
                                            <label for="inputName5" class="form-label">Saisissez votre mot de passe <span className="text-danger">*</span></label>
                                            <input type="password" class="form-control" id="inputName5" name="password" onChange={handleChangeTraitement} value={traitementData.password}/>
                                            {errors && errors.password && (
                                                <span className="text-danger">{errors.password[0]}</span>
                                            )}
                                            {error && <span className="text-danger">{error}</span>}
                                        </div>
                                            
                                    
                                            
                                            <div class="modal-footer mt-3">
                                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button> */}
                                            {loadingform && 
                                            <button class="btn btn-primary" type="button" disabled="">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            En chargement...
                                        </button>}
                                            {!loadingform && <button type="submit" class="btn btn-primary form-control">Valider le traitement</button>}
                                            
                                            </div>
                                    </form>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                   

              
            </ContentPrincipal>
        </>
    )
}

export default Reversement;