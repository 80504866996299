import moment from "moment";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

function ReversementExportPdf({pdfData}){
    const conponentPDF = useRef();


// console.log(pdfData)

// useEffect(()=>{

// },[pdfData]);

        const generatePDF= useReactToPrint({ 

            content: ()=>conponentPDF.current,
            documentTitle:"Transactions pdf",
            onAfterPrint:()=>alert("Data saved in PDF")
        });


        
    

    return (
        <>
         <div style={{ position: 'absolute', left: '-9999px', top: '0' }}>
            <div ref={conponentPDF} style={{width:'80%'}}>
                <h2 class="text-center border-bottom"><u>Liste des reversements</u></h2>
                <table class="table table-striped"  style={{"text-align": "center", height:"20px", paddingTop: 0, paddingBottom: "-5px"}}>
                    <thead className="bg-light">
                    <tr>
                        <th>DATE</th>
                        <th>ENTREPRISE</th>
                        {/* <th>PAYS</th> */}
                        <th>RIB</th>
                        <th>MONTANT</th>
                        <th>STATUT</th>
                        <th>DATE TRAITEMENT</th>
                        <th>COMMENTAIRE</th>
                    </tr>
                    </thead>
                    <tbody>
                
                        {
                            pdfData?.map((reverse,index)=>
                            <tr>
                            <td>{moment(reverse.created_at).format("Do MMMM YYYY")}</td>
                            <td>{reverse.user?.entreprise?.raison_social}</td>
                            {/* <td>{reverse.countrie?.libelle}</td> */}
                            <td>{reverse.rib?.iban}</td>
                            <td>{reverse.montant} <b>XOF</b></td>
                            <td>
                                {reverse.status == 1 && <span class="badge bg-success">Traité</span>}
                                {reverse.status == 3 && <span class="badge bg-danger">Rejeté</span>}
                                {reverse.status == 2 && <span class="badge bg-warning">en traitement</span>}
                                {reverse.status == 0 && <span class="badge bg-info">en confirmation</span>}
                            </td>
                            <td> {reverse.date_traitement && moment(reverse.date_traitement).format("Do MMMM YYYY")}</td>
                            <td>{reverse.commentaire}</td>
                   
                        </tr>
                            )
                        }
                    
                    </tbody>
                </table>
            </div>
        </div>

        <button type="button" class="btn btn-danger float-end mx-1" onClick={generatePDF}>
        <i class="bi bi-star me-1"></i> Export PDF
    </button>
        </>

    )
}

export default ReversementExportPdf;