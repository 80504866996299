import Sidebar from "./Sidebar";
import Header from "./Topbar";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import UserAuth, { UserContext, UserProvider } from "./auth/Usercheck";
import BaseUrl from "./BaseUrl";

const url = BaseUrl();
function ContentPrincipal({children,navactive,ulShownav})
{
  const navigate = useNavigate();
    const {user, setUsers} = useContext(UserContext);

    useEffect(()=>{
        if(localStorage.getItem('_token_') == null){         
          localStorage.removeItem('_token_');
          navigate('/');    
        } 
      if(localStorage.getItem('_token_') !== null){
        try {
            axios.get(url+'/me',
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
           
        }
            ).then((resp)=>{
                if(resp.status == 200){
                    if(resp.data.error){
                        localStorage.removeItem('_token_');
                        navigate('/');
                    }
                    // setUsers(resp.data);
                }else{
                    localStorage.removeItem('_token_');
                    navigate('/');
                }
            })
        } catch (error) {
            console.log(error.response.status);
           
        }
        
    }
      },[user]);

    // console.log(user); 
    return (
        <>
 
            <Header />
            <Sidebar  navactive={navactive} ulShownav={ulShownav} />
              <main id="main" class="main">
                {children}
              </main>
{/* 
              <footer id="footer" class="footer">
                <div class="copyright">
                &copy; Copyright <strong><span>NiceAdmin</span></strong>. All Rights Reserved
                </div>
                <div class="credits">

                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                </div>
            </footer> */}

            <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

        </>
    )
}

export default ContentPrincipal;